import React from "react";
import './App.css';
import './index.css';

import logo from "./logo.png";
import pfp1 from "./pfp1.png";
import pfp2 from "./pfp2.png";
import pfp3 from "./pfp3.png";
import pfp4 from "./pfp4.png";

import {NavLink} from "react-router-dom";

function Go(link) {
    window.open(link);
}

function AboutUs() {
    return (
        <div className="bg-grey pt-60 sm:pt-44 md:pt-28 lg:pt-16">
            <div className="mb-4">
                <div className="align-center">
                    <img src={logo} width={225} alt="Broken Piano" className="sm:visible mx-auto xs:-mt-16 md:mt-0 lg:mt-12" />
                </div>
                <p className="text-8xl text-white text-center font-Staat mt-10">
                    ABOUT US
                </p>
                <p className="text-xl text-white text-center px-5 pt-3">
                    We're a small, independent game company. We make short, simple, and enjoyable games.<br/>We continue creating and updating our games and thrive to learn more about game development every day!
                    <br/><br/>Join our Discord server to discuss our games and ask us questions!
                </p>
                <div className="flex justify-center">
                </div>
            </div>
            <div className="flex justify-center mb-20 mt-16 md:mx-0">
                <iframe className="w-1/1 md:w-1/3" src='https://discord.com/widget?id=833432937168240651&theme=dark' height="350" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
            </div>
            <p className="text-white text-6xl text-center font-Staat mt-32">Development Team</p>
            <div className="mt-16 xs:mx-4 sm:mx-8 md:mx-16 lg:mx-32 xl:mx-64 bg-darkbrown rounded-3xl text-white text-center">
                <p className="text-5xl font-Staat pt-12">Apply Now!</p>
                <p className="text-md md:text-lg mx-8 md:mx-32 mt-2">We're looking for new members for the Broken Piano Team. If you have a useful skill or fit the description of one of our positions, consider joining!</p>
                <div className="flex justify-center pb-12">
                    <NavLink to="/application">
                        <button className="mt-4 bg-brown transition hover:bg-lightishbrown text-white text-center text-lg font-bold rounded-lg px-14 py-2">
                            Application
                        </button>
                    </NavLink>
                </div>
            </div>
            <div className="grid grid-cols-1 grid-rows-4 mt-16">
                <div className="sm:mt-3 grid grid-rows-2 grid-cols-1 sm:grid-rows-1 sm:grid-cols-2 sm:mx-24 md:mx-48 lg:mx-72 xl:mx-[28rem]">
                    <div className="flex justify-center">
                        <img src={pfp1} className="rounded-xl border-solid border-2 border-white h-1/2"/>
                    </div>
                    <div>
                        <p className="text-4xl text-white ml-2 font-Staat -mt-40 sm:mt-0">SuperPhantomUser</p>
                        <p className="text-md text-white ml-2 mt-2">
                            Hi, I'm SuperPhantomUser, the embodiment of the nerd emoji. I'm also the head of Broken Piano. I don't like creepy smiley faces, excersise, or iPhones.
                        </p>
                        <p className="text-lg md:text-xl text-center mt-2 font-bold flex justify-center">
                            <p className="text-[#08a615] mr-2">Designer</p>
                            <p className="text-xl text-center text-white font-bold mr-2">•</p>
                            <p className="text-[#8108a6] mr-2">Programmer</p>
                        </p>
                    </div>
                </div>
                <div className="-mt-64 sm:mt-0 grid grid-rows-2 grid-cols-1 sm:grid-rows-1 sm:grid-cols-2 sm:mx-24 md:mx-48 lg:mx-72 xl:mx-[28rem]">
                    <div className="flex justify-center">
                        <img src={pfp2} className="rounded-xl border-solid border-2 border-white h-1/3 sm:h-1/2"/>
                    </div>
                    <div className="">
                        <p className="text-4xl text-white ml-2 font-Staat -mt-72 sm:mt-0">dotX742</p>
                        <p className="text-white mt-2 ml-2 xl:mx-0 text-md">
                            Corporate says I have to have a description so uhhhhhhhh hello, name's X! I uhh, do math, like making pictures out of squares, and rambling with my friends for the most part. What's the future got for me? No clue, but I'll see to it anyways.
                        </p>
                        <p className="text-xl text-center mt-2 font-bold flex justify-center">
                            <p className="text-[#08a6a3] mr-2">Artist</p>
                            <p className="text-xl text-center text-white font-bold mr-2">•</p>
                            <p className="text-[#0852a6] mr-2">Sprite Editor</p>
                        </p>
                    </div>
                </div>
                <div className="-mt-[30rem] sm:mt-5 grid grid-rows-2 grid-cols-1 sm:grid-rows-1 sm:grid-cols-2 sm:mx-24 md:mx-48 lg:mx-72 xl:mx-[28rem]">
                    <div className="flex justify-center">
                        <img src={pfp3} className="rounded-xl border-solid border-2 border-white h-1/4 sm:h-1/2"/>
                    </div>
                    <div>
                        <p className="text-4xl text-white ml-2 font-Staat -mt-[26rem] sm:mt-0">Munster</p>
                        <p className="text-md text-white ml-2 mt-2">
                            Local hobo who probably should stop holding the dev team hostage for not making the games on time. Special role of director. Also, he likes history, horror and hungry. ভয়ানক গুগল অনুবাদক উপযোগকর্তা.
                        </p>
                        <p className="text-xl text-center mt-2 font-bold flex justify-center">
                            <p className="text-[#a1a608] mr-2">Director</p>
                            <p className="text-xl text-center text-white font-bold mr-2">•</p>
                            <p className="text-[#a67108] mr-2">Marketer</p>
                        </p>
                    </div>
                </div>
                <div className="-mt-[44rem] sm:mt-5 grid grid-rows-2 grid-cols-1 sm:grid-rows-1 sm:grid-cols-2 sm:mx-24 md:mx-48 lg:mx-72 xl:mx-[28rem]">
                    <div className="flex justify-center">
                        <img src={pfp4} className="rounded-xl border-solid border-2 border-white h-1/4 sm:h-1/2"/>
                    </div>
                    <div>
                        <p className="text-4xl text-white ml-2 font-Staat -mt-[30rem] sm:mt-0">MC Cipher</p>
                        <p className="text-md text-white ml-2 mt-2">
                            A guy with a big interest in RPG games, art, and concept art creation. Interested in making new games and fresh concepts. He has a good heart and is motivated to work with the rest of the team.
                        </p>
                        <p className="text-xl text-center mt-2 font-bold flex justify-center">
                            <p className="text-[#a1a608] mr-2">Director</p>
                            <p className="text-xl text-center text-white font-bold mr-2">•</p>
                            <p className="text-[#a67108] mr-2">Marketer</p>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

/*

<div className="mt-16 xs:mx-4 sm:mx-8 md:mx-16 lg:mx-32 xl:mx-64 bg-darkbrown rounded-3xl text-white text-center">
                <p className="text-5xl font-Staat pt-12">Apply Now!</p>
                <p className="text-md md:text-lg mx-8 md:mx-32 mt-2">We're looking for new members for the Broken Piano Team. If you have a useful skill or fit the description of one of our positions, consider joining!</p>
                <div className="flex justify-center pb-12">
                    <NavLink to="/application">
                        <button className="mt-4 bg-brown transition hover:bg-lightishbrown text-white text-center text-lg font-bold rounded-lg px-14 py-2">
                            Application
                        </button>
                    </NavLink>
                </div>
            </div>

*/

export default AboutUs;