import React from "react";
import './App.css';
import './index.css';

import image from "./pianorun_logo_blank.png";
import banner from "./banner_pianorun.png";

function InfoboxF(link) {
    window.open(link);
}

function InfoboxFSelf(link) {
    window.open(link, "_self");
}

function ThisGame() {
    return (
        <div className="bg-darkbrown pt-20">
            <img src={banner} alt="Piano Run" className="scale-100"/>
            <div className="mb-5 mt-24">
                <p className="text-2xl text-white text-center font-bold mt-8 xs:mx-2 md:mx-5">
                    Piano Run is a simple fast-paced infinite platformer!
                </p>
                <p className="text-xl text-white text-center px-5 pt-3">
                    You're the last broken piano. Survive for as long as you can from the tuners trying to fix you...
                    <br/>The game is easy to learn, but hard to master. Collect music notes to raise your score, and avoid the piano tuners!
                </p>
            </div>
            <div className="mt-24">
                <p className="text-5xl text-white text-center font-Staat mt-5">
                    PLAY IT ON:
                </p>
                <div className="grid grid-rows-3 gap-3 justify-items-center md:flex justify-center mt-4">
                    <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2 mr-4" onClick={() => InfoboxF("https://pianorun.superphantom1.repl.co")}>
                        Browser
                    </button>
                    <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2 mr-4" onClick={() => InfoboxF("https://gamejolt.com/games/piano-run/749199")}>
                        Gamejolt
                    </button>
                    <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2 mr-4" onClick={() => InfoboxF("https://broken-piano-studios.itch.io/piano-run")}>
                        Itch.io
                    </button>
                </div>
            </div>
            <div className="bg-darkgrey pt-20 pb-20 mt-24">
                <p className="text-5xl text-white text-center font-Staat">
                    GAME PREVIEW:
                </p>
                <p className="text-xl text-white text-center mt-2">Game preview only works on desktop devices.</p>
                <div className="flex justify-center mt-5">
                    <iframe className="rounded-xl border-4 border-brown" src="https://pianorun.superphantom1.repl.co" title="Piano Run" allowtransparency="true" width="63%" height="550" frameborder="0" scrolling="no" allowfullscreen></iframe>
                </div>
            </div>
            <div className="bg-lightgrey pt-4 pb-5">
                <p className="text-4xl text-white text-center font-Staat mt-4">
                    Back to Games:
                </p>
                <div className="flex justify-center">
                    <button className="mt-4 bg-lightishgrey transition hover:bg-grey text-white text-center text-lg font-bold rounded-lg px-14 py-2" onClick={() => InfoboxFSelf("/games")}>
                        Games
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ThisGame;