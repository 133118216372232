import React from "react";
import './App.css';
import {NavLink} from "react-router-dom";
import logo from "./logo.png";
/*import { NavDropdown, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';*/

function NavBar() {
    return (
        <div>
            <nav className="fixed top-0 left-0 right-0 z-50 navbar navbar-expand bg-grey sm:px-24 py-3 text-white">
                <NavLink className="navbar-brand" to="/">
                    <img src={logo} alt="" className="mr-3" width={50} height={0.5}/>
                </NavLink>
                <NavLink className="navbar-brand transition hover:text-verylightbrown" to="/">
                    <div className="">
                        <div className="">
                            <span className="text-sm md:text-xl">Broken Piano</span>
                        </div>
                    </div>
                </NavLink>
                <ul className="navbar-nav ml-auto text-white">
                    <li className="nav-item">
                        <NavLink className="navbar-brand transition hover:text-verylightbrown" to="/games">
                            <span className="text-sm md:text-xl mr-3">Games</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="navbar-brand transition hover:text-verylightbrown" to="/about-us">
                            <span className="text-sm md:text-xl">Team</span>
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default NavBar;