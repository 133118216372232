import React from "react";
import './App.css';
import './index.css';

import logo from "./logo.png";
import pfp1 from "./pfp1.png";
import pfp2 from "./pfp2.png";
import pfp3 from "./pfp3.png";

import {useState, useRef} from "react";

function Go(link) {
    window.open(link);
}

function Application() {
    const [app, setApp] = useState(<div></div>);
    const Composer = useRef(null);
    const Moderator = useRef(null);
    const Translator = useRef(null);

    const dUsername = useRef(null);
    const dEmail = useRef(null);
    const dMusic1 = useRef(null);
    const dMusic2 = useRef(null);
    const dMusic3 = useRef(null);
    const dMusic4 = useRef(null);
    const dMusic5 = useRef(null);
    const dExperience = useRef(null);
    const dProgram = useRef(null);
    const dLinks = useRef(null);
    const dGames1 = useRef(null);
    const dGames2 = useRef(null);
    const dFound = useRef(null);
    const dImportant = useRef(null);

    const mUsername = useRef(null);
    const mEmail = useRef(null);
    const mTimezone = useRef(null);
    const mOnline = useRef(null);
    const mModerated1 = useRef(null);
    const mModerated2 = useRef(null);
    const mExperience = useRef(null);
    const mOwned1 = useRef(null);
    const mOwned2 = useRef(null);
    const mGames1 = useRef(null);
    const mGames2 = useRef(null);
    const mHelp = useRef(null);
    const mImportant = useRef(null);

    const tUsername = useRef(null);
    const tEmail = useRef(null);
    const tLanguage = useRef(null);
    const tProficiency = useRef(null);
    const tGames1 = useRef(null);
    const tGames2 = useRef(null);
    
    const [confirm, setConfirm] = useState("");

    function SubmitApp(type) {
        let username;
        let email;
        let experience;
        let games1;
        let games2;
        let important;
        let complete = true;
        if (type == "Developer") {
            username = dUsername.current.value;
            email = dEmail.current.value;
            experience = dExperience.current.value;
            let program = dProgram.current.value;
            let links = dLinks.current.value;
            games1 = dGames1.current.value;
            games2 = dGames2.current.value;
            let found = dFound.current.value;
            important = dImportant.current.value;
            let music = 0;
            if (dMusic1.current.checked) music = 1;
            else if (dMusic2.current.checked) music = 2;
            else if (dMusic3.current.checked) music = 3;
            else if (dMusic4.current.checked) music = 4;
            else if (dMusic5.current.checked) music = 5;
            if (!(username && program && links && games1 && games2 && found && important)) complete = false;
            if (music == 0) complete = false;
            let musicArray = ["3-5 years", "1-2 years", "6+ months", "3-6 months", "1-3 months"];
            if (!complete) return setConfirm("Please fill in all required fields!");
            const content = {
                "embeds": [{
                  "title": "New Developer Application",
                  "description": `Discord Username/Tag: ${username}\nEmail: ${email}`,
                  "fields": [
                    {
                        name: "How much experience in digital music do you have?",
                        value: `${musicArray[music - 1]}\nAdditional Info: ${experience}`
                    },
                    {
                        name: "What program(s) do you use to create music online?",
                        value: program
                    },
                    {
                        name: "Have you made any soundtracks for games or other media? If so, link them here.",
                        value: links
                    },
                    {
                        name: "Personal Info",
                        value: `Which Broken Piano Games have you played?\n${games1}\n\nWhat are your favorite games to play in your free time?\n${games2}\n\nHow did you find out about Broken Piano?\n${found}`
                    },
                    {
                        name: "Why will you be a valuable addition to the Development Team?",
                        value: important
                    }
                  ]
                }]
              };
              fetch("https://discord.com/api/webhooks/1141079373823344693/dzACiYyuWBs5qS9UYXtNl7gQN_60WNtI2XBjtFTigb5eIlYZXwqjObx18x_HATZwWRyc", {
                method: "POST",
                body: JSON.stringify(content),
                headers: {
                  "Content-Type": "application/json"
                }
              });
              setConfirm("Application submitted!");
        } else if (type == "Moderator") {
            username = mUsername.current.value;
            email = mEmail.current.value;
            let timezone = mTimezone.current.value;
            let online = mOnline.current.value;
            experience = mExperience.current.value;
            games1 = mGames1.current.value;
            games2 = mGames2.current.value;
            let help = mHelp.current.value;
            important = mImportant.current.value;
            let moderated = "";
            if (mModerated1.current.checked) moderated = "Yes";
            else if (mModerated2.current.checked) moderated = "No";
            let owned = "";
            if (mOwned1.current.checked) owned = "Yes";
            else if (mOwned2.current.checked) owned = "No";
            if (!(username && timezone && online && games1 && games2 && help && important)) complete = false;
            if (!moderated || !owned) complete = false;
            if (!complete) return setConfirm("Please fill in all required fields!");
            const content = {
                "embeds": [{
                  "title": "New Moderator Application",
                  "description": `Discord Username/Tag: ${username}\nTimezone: ${timezone}\nOnline: ${online}\nEmail: ${email}`,
                  "fields": [
                    {
                        name: "Experience",
                        value: `Have you moderated a public Discord server before?\n${moderated}\n\nHave you owned a public Discord server before?\n${owned}\n\nAdditional Info: ${experience}`
                    },
                    {
                        name: "Personal Info",
                        value: `Which Broken Piano Games have you played?\n${games1}\n\nWhat are your favorite games to play in your free time?\n${games2}`
                    },
                    {
                        name: "How will you make the Broken Piano Discord server a safer and more enjoyable place?",
                        value: help
                    },
                    {
                        name: "Why should we accept you as a Moderator?",
                        value: important
                    }
                  ]
                }]
              };
              fetch("https://discord.com/api/webhooks/1141079373823344693/dzACiYyuWBs5qS9UYXtNl7gQN_60WNtI2XBjtFTigb5eIlYZXwqjObx18x_HATZwWRyc", {
                method: "POST",
                body: JSON.stringify(content),
                headers: {
                  "Content-Type": "application/json"
                }
              });
              setConfirm("Application submitted!");
        } else {
            username = tUsername.current.value;
            email = tEmail.current.value;
            let language = tLanguage.current.value;
            let proficiency = tProficiency.current.value;
            games1 = tGames1.current.value;
            games2 = tGames2.current.value;
            if (!(username && language && proficiency && games1 && games2)) complete = false;
            if (!complete) return setConfirm("Please fill in all required fields!");
            const content = {
                "embeds": [{
                  "title": "New Translator Application",
                  "description": `Discord Username/Tag: ${username}\n\nLanguage: ${language}\nProficiency in Language: ${proficiency}\n\nEmail: ${email}`,
                  "fields": [
                    {
                        name: "Personal Info",
                        value: `Which Broken Piano Games have you played?\n${games1}\n\nWhat are your favorite games to play in your free time?\n${games2}`
                    }
                  ]
                }]
              };
              fetch("https://discord.com/api/webhooks/1141079373823344693/dzACiYyuWBs5qS9UYXtNl7gQN_60WNtI2XBjtFTigb5eIlYZXwqjObx18x_HATZwWRyc", {
                method: "POST",
                body: JSON.stringify(content),
                headers: {
                  "Content-Type": "application/json"
                }
              });
              setConfirm("Application submitted!");
        }
    }

    function onChange() {
        /*if (Composer.current.checked) {
            setApp(<div>
                <div className="text-white mt-8 ml-24 px-5 mr-96">
                    <p className="text-5xl text-white font-Staat mt-5">Developer Application</p>
                    <p className="text-xl text-white mt-3">As the Team Composer, you will create digital music for our games. You will become a VIP before being accepted as a full Team member. At this time, Developers do not receive any payment. (Besides the joy of working on games!)<br/><br/>You <b>must</b> know at least one developer in real life in order to qualify.</p>
                    <form className="mt-5">
                        <div className="text-lg mt-4">
                            <p className="">What is your Discord username or tag?</p>
                            <p className="text-lightgrey">You must have a Discord account and join the Broken Piano Discord server to apply.</p>
                            <input type="text" placeholder="joe_schmoe" ref={dUsername} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>What is your email? (optional)</p>
                            <input type="text" placeholder="joeschmoe@brokenpiano.org" ref={dEmail} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>How much experience in digital music do you have?</p>
                            <input type="radio" name="" value="1" ref={dMusic1}/>3-5 years<br/>
                            <input type="radio" name="" value="2" ref={dMusic2}/>1-2 years<br/>
                            <input type="radio" name="" value="3" ref={dMusic3}/>6+ months<br/>
                            <input type="radio" name="" value="4" ref={dMusic4}/>3-6 months<br/>
                            <input type="radio" name="" value="3" ref={dMusic5}/>1-3 months<br/>
                            <p>Tell us more about your experience in music: (optional)</p>
                            <textarea placeholder="" rows="2" cols="45" ref={dExperience} className="h-1/2 rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>What program(s) do you use to create music online?</p>
                            <input type="text" placeholder="" ref={dProgram} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>Have you made any soundtracks for games or other media? If so, explain and link them here.</p>
                            <textarea placeholder="" rows="4" cols="45" ref={dLinks} className="h-1/2 rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>Which Broken Piano games have you played?</p>
                            <input type="text" placeholder="" ref={dGames1} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>What are your favorite games to play in your free time?</p>
                            <p className="text-lightgrey">(Don't worry, they don't have to be Broken Piano games)</p>
                            <input type="text" placeholder="" ref={dGames2} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>How did you find out about Broken Piano?</p>
                            <textarea placeholder="" rows="2" cols="45" ref={dFound} className="h-1/2 rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>Why will you be a valuable addition to the Development Team?</p>
                            <p className="text-lightgrey">(This is an important question, woah)</p>
                            <textarea placeholder="" rows="4" cols="45" ref={dImportant} className="h-1/2 rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                    </form>
                    <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2 mr-4 mt-3" onClick={() => SubmitApp("Developer")}>
                        Submit
                    </button>
                </div>
            </div>);
        } else*/ if (Moderator.current.checked) {
            setApp(<div>
                <div className="text-white mt-8 xs:ml-5 md:ml-24 xs:px-1 md:px-5 md:mr-96">
                    <p className="text-5xl text-white font-Staat mt-5">Moderator Application</p>
                    <p className="text-xl text-white mt-3">As a Moderator, you will make sure that our growing Discord server stays safe. You will have Moderator permissions, such as kicking, banning, and timeouts, but will be demoted if you abuse your power. At this time, Moderators do not receive any payment.</p>
                    <form className="mt-5">
                        <div className="text-lg mt-4">
                            <p className="">What is your Discord username or tag?</p>
                            <p className="text-lightgrey">You must have a Discord account and join the Broken Piano Discord server to apply.</p>
                            <input type="text" placeholder="joe_schmoe" ref={mUsername} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30" required/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>What is your email? (optional)</p>
                            <input type="text" placeholder="joeschmoe@brokenpiano.org" ref={mEmail} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p className="">What is your timezone?</p>
                            <p className="text-lightgrey">If you don't know, simply search up "what is my timezone?"</p>
                            <input type="text" placeholder="EDT" ref={mTimezone} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30" required/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>What times are you online on Discord?</p>
                            <input type="text" placeholder="5:00pm to 8:00pm..." ref={mOnline} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>Have you moderated a public Discord server before?</p>
                            <input type="radio" name="" value="1" ref={mModerated1}/>Yes<br/>
                            <input type="radio" name="" value="2" ref={mModerated2}/>No<br/>
                            <p>If you did moderate, tell us more about your experience: (optional)</p>
                            <textarea placeholder="" rows="2" cols="45" ref={mExperience} className="h-1/2 rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>Have you owned a public Discord server before?</p>
                            <input type="radio" name="" value="1" ref={mOwned1}/>Yes<br/>
                            <input type="radio" name="" value="2" ref={mOwned2}/>No
                        </div>
                        <div className="text-lg mt-5">
                            <p>Which Broken Piano games have you played?</p>
                            <input type="text" placeholder="" ref={mGames1} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>What are your favorite games to play in your free time?</p>
                            <p className="text-lightgrey">(Don't worry, they don't have to be Broken Piano games)</p>
                            <input type="text" placeholder="" ref={mGames2} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>How will you make the Broken Piano Discord server a safer and more enjoyable place?</p>
                            <textarea placeholder="" rows="4" cols="45" ref={mHelp} className="h-1/2 rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>Why should we accept you as a Moderator?</p>
                            <p className="text-lightgrey">(Woah, a really important question here)</p>
                            <textarea placeholder="" rows="4" cols="45" ref={mImportant} className="h-1/2 rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                    </form>
                    <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2 mr-4 mt-3" onClick={() => SubmitApp("Moderator")}>
                        Submit
                    </button>
                </div>
            </div>);
        } else {
            setApp(<div>
                <div className="text-white mt-8 xs:ml-5 md:ml-24 xs:px-1 md:px-5 md:mr-96">
                    <p className="text-5xl text-white font-Staat mt-5">Translator Application</p>
                    <p className="text-xl text-white mt-3">As a Translator, you will translate and localize (as best you can) all text. At this time, Translators do not receive any payment.<br/><br/>You <b>must</b> be fluent in writing of English and of the language you are translating to to qualify.</p>
                    <form className="mt-5">
                        <div className="text-lg mt-4">
                            <p className="">What is your Discord username or tag?</p>
                            <p className="text-lightgrey">You must have a Discord account and join the Broken Piano Discord server to apply.</p>
                            <input type="text" placeholder="joe_schmoe" ref={tUsername} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30" required/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>What is your email? (optional)</p>
                            <input type="text" placeholder="joeschmoe@brokenpiano.org" ref={tEmail} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p className="">Which language do you wish to translate to?</p>
                            <input type="text" placeholder="" ref={tLanguage} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30" required/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>How well do you know that language?</p>
                            <input type="text" placeholder="" ref={tProficiency} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>Which Broken Piano games have you played?</p>
                            <input type="text" placeholder="" ref={tGames1} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                        <div className="text-lg mt-5">
                            <p>What are your favorite games to play in your free time?</p>
                            <p className="text-lightgrey">(Don't worry, they don't have to be Broken Piano games)</p>
                            <input type="text" placeholder="" ref={tGames2} className="rounded-sm text-white bg-darkgrey px-2 mt-1" size="30"/>
                        </div>
                    </form>
                    <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2 mr-4 mt-3" onClick={() => SubmitApp("Translator")}>
                        Submit
                    </button>
                </div>
            </div>);
        }
    }

    return (
        <div className="bg-grey pt-60 sm:pt-44 md:pt-28 lg:pt-16">
            <div className="">
                <p className="text-8xl text-white text-center font-Staat mt-16">
                    JOIN OUR TEAM
                </p>
                <p className="text-xl text-white px-5 mt-9 xs:ml-5 md:ml-24 md:mr-96">
                    We're currently looking for Moderators for our Discord Server and Translators for our games. If you want to help us out, feel free to apply using the form below.
                </p>
                <div className="text-xl text-white px-5 mt-5 xs:ml-5 md:ml-24 md:mr-96">
                    What do you want to apply for?<br/>
                    <input type="radio" name="fav_color" value="Moderator" ref={Moderator} onChange={() => onChange()}/>Moderator<br/>
                    <input type="radio" name="fav_color" value="Translator" ref={Translator} onChange={() => onChange()}/>Translator<br/>
                </div>
                {app}
                <p className="text-md text-white px-5 xs:ml-2 md:ml-24 md:mr-96 mt-4">{confirm}</p>
                <div className="pb-16"/>
            </div>
        </div>
    );
}

function ApplicationClosed() {
    return (
        <div className="bg-grey pt-60 sm:pt-44 md:pt-28 lg:pt-16">
            <div className="">
                <p className="text-8xl text-white text-center font-Staat mt-16">
                    JOIN OUR TEAM
                </p>
                <p className="text-xl text-white px-5 mt-12 ml-24 pb-44">
                    Sorry, we're not currently looking for new Staff members.<br/>Check back here for when we open up applications, though!
                </p>
            </div>
        </div>
    );
}

export default Application;