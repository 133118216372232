import React, {useRef, useEffect} from "react";
import './App.css';
import './index.css';

import logo from "./logo.png";
import games from "./banner_games.png";
import battle from "./banner_battle.png";
import more from "./banner_about.png";

import slide1 from "./slide_spikelaunch.png";
import slide2 from "./slide_pianorun.png";
//import slide2 from "./slide_tnitr.png";
//import slide3 from "./slide_legendaria.png";
import slide3 from "./slide_bjh.png";
//import slide5 from "./slide_gwg.png";
//import slide6 from "./slide_bj2.png";
import slide4 from "./slide_snap.png";

import facebook from "./facebook.png";
import youtube from "./youtube.png";
import twitter from "./twitter.png";
import reddit from "./reddit.png";
import discord from "./discord.png";

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

//import ImageGallery from "react-image-gallery";

import {NavLink} from "react-router-dom";

import MailchimpSubscribe from "react-mailchimp-subscribe";

const images = [
    {original: slide1}, {original: slide2}, {original: slide3}, {original: slide4}
];

function Link(link) {
    window.open(link);
}

function Infobox(props) {
    return (
        <div className="items-center content-center rounded-lg bg-darkishbrown pt-6 pb-10">
            <img src={props.src} alt="" className="mx-auto scale-100"/>
            <p className="text-4xl text-white text-center mt-3 font-Staat">
                {props.name}
            </p>
            <p className="text-md text-white text-center mx-10 md:mx-16 mt-3">
                {props.desc}
            </p>
            <div className="flex justify-center">
                <NavLink to={props.link}>
                    <button className="rounded-lg bg-lightbrown transition hover:bg-lightishbrown mt-5 px-5 py-1 font-bold text-white self-center">
                        Go
                    </button>
                </NavLink>
            </div>
        </div>
    );
}

function CustomForm({status, message, onValidated}) {
    const textRef1 = useRef(null);
    const textRef2 = useRef(null);
    const textRef3 = useRef(null);

    function handleSubmit(e) {
        e.preventDefault();
        textRef1.current.focus();
        textRef2.current.focus();
        textRef3.current.focus();
        let email = textRef1.current.value;
        let first = textRef2.current.value;
        let last = textRef3.current.value;
        email &&
        first &&
        last &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
            MERGE1: first,
            MERGE2: last
        });
    }

    /*useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        document.getElementsByClassName("twitter-embed")[0].appendChild(script);
    }, []);*/

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <label className="block">
                <div className="">
                    <p className="text-2xl text-white text-center font-bold mx-3">Email</p>
                    <div className="flex justify-center">
                        <input ref={textRef1} placeholder="your@email.com" type="email" className="rounded-md text-xl text-center text-lg bg-white border-solid border-4 border-lightbrown transition focus:border-lightishbrown focus:outline-none text-black w-11/12 h-12"/>
                    </div>
                </div>
            </label>
            <label className="block mb-4">
                <div className="grid grid-rows-2 md:grid-rows-1 grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <p className="text-2xl text-white text-center font-bold mx-3">First Name</p>
                        <input ref={textRef2} placeholder="Joe" type="text" className="rounded-md text-xl text-center text-lg bg-white border-solid border-4 border-lightbrown transition focus:border-lightishbrown focus:outline-none text-black w-175 h-12"/>
                    </div>
                    <div>
                        <p className="text-2xl text-white text-center font-bold mx-3">Last Name</p>
                        <input ref={textRef3} placeholder="Schmoe" type="text" className="rounded-md text-xl text-center text-lg bg-white border-solid border-4 border-lightbrown transition focus:border-lightishbrown focus:outline-none text-black w-175 h-12"/>
                    </div>
                </div>
            </label>
            <div className="flex justify-center mb-3">
                <input type="submit" value="Subscribe" className="bg-lightbrown transition hover:bg-lightishbrown font-bold text-white px-5 py-2 rounded-lg"/>
            </div>
            {status === "sending" && (
                <div className="mc__alert mc__alert--sending text-white text-center font-bold">
                    Sending...
                </div>
            )}
            {status === "error" && (
                <div className="mc__alert mc__alert--error text-red text-center font-bold">
                    {message.includes("<a") ? message.substring(0, message.indexOf("<")) : message}
                </div>
            )}
            {status === "success" && (
                <div className="mc__alert mc__alert--success text-green text-center font-bold">
                    {message}
                </div>
            )}
        </form>
    );
}

function Home() {
    const postUrl = `https://app.us20.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
    return (
        <div className="bg-gradient-to-t from-darkbrown to-grey">
            <div className="align-center pt-60 sm:pt-44 md:pt-28 lg:pt-16">
                <div className="align-center">
                    <div className="animate-slidedown">
                        <img src={logo} alt="Broken Piano" className="sm:visible scale-[0.35] mx-auto -my-56 -mt-60" />
                    </div>
                </div>
                <div className="animate-slideup">
                    <p className="text-6xl text-white text-center mt-36 md:-mt-24 font-Staat">Simple games. Addictive gameplay.</p>
                    <p className="text-lg text-white text-center mt-2 md:mt-1 mx-5">
                        At Broken Piano, we offer a variety of games and projects that'll leave you wanting more.
                    </p>
                </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 lg:grid-rows-1 grid-rows-3 gap-5 mt-28 sm:mx-4 lg:mx-16">
                <Infobox src={battle} name="Legacy Games" link="/games/battle-jellies" desc="Take a trip down memory lane and see some of our first games. They don't have the same quality as our official games, but the same originality and fun."/>
                <Infobox src={games} name="Our Games" link="/games" desc="We specialize in games! Whether you're crashing into crates, running away from tuners, or surviving waves of skeletons, our games are simple but fun to play."/>
                <Infobox src={more} name="Learn More" link="/about-us" desc="All of us have a special role in the Broken Piano Team and help to make great games. If you have a skill that we're looking for, consider joining the Team!"/>
            </div>
            <div className="">
                <p className="text-5xl text-white text-center font-Staat mt-28 -mb-8 md:-mb-16 lg:-mb-24">See what's new:</p>
                <div className="lg:flex lg:justify-center mt-16 mx-8 lg:mx-0">
                    <div className="flex justify-center">
                        <iframe style={{border: "none"}} height="465" data-tweet-url="https://twitter.com/brokenpiano01" className="sm:mt-3 lg:mt-28 lg:mr-5 w-[30rem] border-lightgrey border-4 rounded-md text-white rounded-xl" src="data:text/html;charset=utf-8,%3Ca%20class%3D%22twitter-timeline%22%20href%3D%22https%3A//twitter.com/brokenpiano01%3Fref_src%3Dtwsrc%255Etfw%22%3ETweets%20by%20brokenpiano01%3C/a%3E%0A%3Cscript%20async%20src%3D%22https%3A//platform.twitter.com/widgets.js%22%20charset%3D%22utf-8%22%3E%3C/script%3E%0A"></iframe>
                    </div>
                    <div className="grid grid-cols-1 grid-rows-2 gap-4">
                        <div className="flex justify-center">
                            <iframe className="border-4 border-slblue mt-16 lg:mt-28 rounded-xl" width="400" height="225" src="https://youtube.com/embed/Ve0uwsaIq2E" title="Spike Launch 1.1 Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="flex justify-center">
                            <iframe className="border-4 border-slblue rounded-xl" width="400" height="225" src="https://youtube.com/embed/vm0UcenKa3s" title="Spike Launch On iOS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-darkishbrown mt-3">
                <div className="pt-20 pb-6">
                    <p className="text-6xl text-white text-center font-Staat">We may be broken,</p>
                    <p className="text-3xl text-white text-center mt-2 font-Staat mx-2">But make sure you can still hear.</p>
                </div>
                <div className="pb-6">
                    <p className="text-xl text-verylightbrown text-center font-bold mx-3">First, follow our social media:</p>
                    <div className="pt-6 flex justify-center space-x-8 mx-4">
                        <button className="transition ease-in-out hover:scale-[1.2]" onClick={() => Link("https://www.facebook.com/Broken-Piano-103217712371089")}>
                            <img src={facebook} alt="Facebook" className=""/>
                        </button>
                        <button className="transition ease-in-out hover:scale-[1.2]" onClick={() => Link("https://twitter.com/brokenpiano01")}>
                            <img src={twitter} alt="Twitter" className=""/>
                        </button>
                        <button className="transition ease-in-out hover:scale-[1.2]" onClick={() => Link("https://www.youtube.com/channel/UCdeEq_rgorYMnt9V3dYPDkQ")}>
                            <img src={youtube} alt="Youtube" className=""/>
                        </button>
                        <button className="transition ease-in-out hover:scale-[1.2]" onClick={() => Link("https://www.reddit.com/r/brokenpiano/")}>
                            <img src={reddit} alt="Reddit" className=""/>
                        </button>
                        <button className="transition ease-in-out hover:scale-[1.2]" onClick={() => Link("https://discord.gg/C2GHU7H6bp")}>
                            <img src={discord} alt="Discord" className=""/>
                        </button>
                    </div>
                </div>
                <div className="pb-6">
                    <p className="text-xl text-verylightbrown text-center font-bold mx-3">Then enter your email to sign up for our mailing list!</p>
                </div>
                <div className="flex justify-center">
                    <div className="">
                        <MailchimpSubscribe
                            url={postUrl}
                            render={({subscribe, status, message}) => (
                                <CustomForm
                                    status={status} 
                                    message={message}
                                    onValidated={formData => subscribe(formData)}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="flex justify-center pb-4">
                    <a href="mailto:emailus@brokenpiano.org">
                        <button className="bg-lightbrown transition hover:bg-lightishbrown font-bold text-white px-5 py-2 rounded-lg">
                            Contact Us
                        </button>
                    </a>
                </div>
                <div className="pb-16">
                    <p className="text-lg text-white text-center mx-5">Get notifications about new games and updates! We'll keep your email safe and sound.</p>
                </div>
            </div>
        </div>
    );
}

// add shadow to text

/*

Contact Us Button
<button className="rounded-lg bg-brown hover:bg-darkbrown text-center text-white text-lg px-10 py-2" onClick={ContactUs}>
                        Contact Us
                    </button>

Original form
<form className="pb-6">
                    <label className="block">
                        <div className="flex justify-center">
                            <input ref={textInput} type="email" className="rounded-md text-xl text-center text-lg bg-white border-solid border-4 border-brown text-black w-75 h-12"/>
                        </div>
                    </label>
                </form>




                <AwesomeSlider
                    infinite={true}
                    bullets={false}
                    organicArrows={true}
                    className="scale-[0.5] -mt-64 rounded-md border-lightgrey border-4"
                >
                    <div>
                        <button onClick={() => {window.open("https://play.google.com/store/apps/details?id=com.brokenpiano.com.spikelaunch");}}><img src={slide1} alt="Spike Launch"/></button>
                    </div>
                    <div>
                        <button onClick={() => {window.open("https://gamejolt.com/games/piano-run/749199");}}><img src={slide2} alt="Piano Run"/></button>
                    </div>
                    <div>
                        <button onClick={() => {window.open("https://gamejolt.com/games/bj-hunter/751727");}}><img src={slide3} alt="Battle Jellies Hunter"/></button>
                    </div>
                    <div>
                        <button onClick={() => {window.open("https://gamejolt.com/games/snap/709717");}}><img src={slide4} alt="SNAP!"/></button>
                    </div>
                </AwesomeSlider>
*/

export default Home;