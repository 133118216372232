import React from "react";
import './App.css';
import './index.css';

import image from "./bj2_logo_blank.png";
import banner from "./banner_battlejellies.png";

function InfoboxF(link) {
    window.open(link);
}

function InfoboxFSelf(link) {
    window.open(link, "_self");
}

function ThisGame() {
    return (
        <div className="bg-darkgreen pt-20">
            <img src={banner} alt="Piano Run" className="scale-100"/>
            <div className="mb-5">
                <p className="text-2xl text-white text-center font-bold mt-24 mx-8 md:mx-24 lg:mx-80">
                    Battle Jellies is a series taking place in the JACK The Jelly universe - defeat enemies to take over the world!
                </p>
            </div>
            <div className="bg-darkishbrown pt-5 pb-20 mt-24 xs:mx-2 md:mx-8 lg:mx-20 rounded-3xl">
                <p className="xs:text-4xl sm:text-5xl text-white text-center font-Staat">
                    BATTLE JELLIES 2
                </p>
                <p className="text-xl text-white text-center mt-2 mx-2">The second and most popular game of the Battle Jellies series.</p>
                <div className="grid grid-rows-2 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2 mt-3">
                    <div>
                        <p className="xs:text-lg sm:text-xl text-white text-center xs:mx-2 sm:mx-5 mt-3">Journey through several worlds and chapters as you work to conquer the world...and more?</p>
                        <ul className="text-white xs:text-lg sm:text-xl text-center xs:mx-2">
                            <li>3 Story Mode chapters, each with 90 total stages!</li>
                            <li>Over 100 special and endless stages!</li>
                            <li>Unlock a sacred treasure in Adventure Stages!</li>
                            <li>Challenge the Dark Universe in the most recent update!</li>
                        </ul>
                        <p className="text-4xl text-white text-center font-Staat mt-5">
                            PLAY IT ON:
                        </p>
                        <div className="grid grid-rows-3 gap-3 justify-items-center md:flex md:justify-center mt-4">
                            <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2 mr-4" onClick={() => InfoboxF("https://scratch.mit.edu/projects/620149313/")}>
                                Scratch
                            </button>
                            <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2 mr-4" onClick={() => InfoboxF("https://gamejolt.com/games/battle-jellies-2/680605")}>
                                Gamejolt
                            </button>
                            <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2 mr-4" onClick={() => InfoboxF("https://broken-piano-studios.itch.io/battle-jellies-2")}>
                                Itch.io
                            </button>
                        </div>
                    </div>
                    <div>
                        <p className="text-4xl text-white text-center font-Staat xs:mt-12 lg:mt-2">
                            GAME PREVIEW:
                        </p>
                        <p className="text-lg text-white text-center">Game preview only works on desktop devices.</p>
                        <div className="justify-items-center flex justify-center mt-4">
                            <iframe className="rounded-lg border-8 border-brown" src="https://scratch.mit.edu/projects/620149313/embed" title="Battle Jellies 2" allowtransparency="true" width="485" height="402" frameborder="0" scrolling="no" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-darkishbrown pt-5 pb-20 mt-16 xs:mx-2 md:mx-8 lg:mx-20 rounded-3xl">
                <p className="text-5xl text-white text-center font-Staat">
                    BATTLE JELLIES HUNTER
                </p>
                <p className="text-xl text-white text-center mt-2 mx-2">A Battle Jellies spinoff, in the style of an action-packed infinite shooter.</p>
                <div className="mt-3">
                    <div>
                        <p className="xs:text-lg sm:text-xl text-white text-center mt-3 xs:mx-5 md:mx-20 lg:mx-32">Battle Jellies is back in a new installment! Battle Jellies Hunter is an action-packed infinite shooter - locate jellies, aim, and fire! Includes various jellies from the Battle Jellies series, and several different maps to play on!</p>
                        <p className="text-4xl text-white text-center font-Staat mt-5">
                            PLAY IT ON:
                        </p>
                        <div className="grid grid-rows-3 gap-3 justify-items-center md:flex justify-center mt-4">
                            <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2 mr-4" onClick={() => InfoboxF("https://scratch.mit.edu/projects/726143137/")}>
                                Scratch
                            </button>
                            <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2 mr-4" onClick={() => InfoboxF("https://gamejolt.com/games/bj-hunter/751727")}>
                                Gamejolt
                            </button>
                            <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2 mr-4" onClick={() => InfoboxF("https://broken-piano-studios.itch.io/battle-jellies-hunter")}>
                                Itch.io
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-darkishbrown pt-5 pb-20 mt-16 xs:mx-2 md:mx-8 lg:mx-20 rounded-3xl">
                <p className="text-5xl text-white text-center font-Staat">
                    BATTLE JELLIES
                </p>
                <p className="text-xl text-white text-center mt-2 mx-2">The original Battle Jellies game.</p>
                <div className="mt-3">
                    <div>
                        <p className="xs:text-lg sm:text-xl text-white text-center mt-3 mx-4">Battle Jellies, unlike its two successors, is a turn-based strategy game. It's based off the mobile game "The Battle Cats."<br/>Note: because of a Scratch glitch, the game may not work for some users.</p>
                        <p className="text-4xl text-white text-center font-Staat mt-5">
                            PLAY IT ON:
                        </p>
                        <div className="grid grid-rows-3 gap-4 justify-items-center md:flex justify-center mt-4">
                            <button className="bg-lightbrown transition hover:bg-lightishbrown rounded-lg text-white text-lg font-bold px-5 py-2" onClick={() => InfoboxF("https://scratch.mit.edu/projects/561395865/")}>
                                Scratch
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pb-16 pt-3 bg-darkgrey mt-16">
                <p className="text-4xl text-white text-center font-Staat mt-5">
                    Need some help?
                </p>
                <div className="flex justify-center mt-4 mx-2">
                    <button className="bg-darkishbrown transition hover:bg-darkbrown rounded-lg text-white text-xl font-bold px-16 md:px-24 py-3" onClick={() => InfoboxF("https://battle-jellies-2.fandom.com/wiki/Battle_Jellies_2_Wiki")}>
                        Battle Jellies 2 Wiki
                    </button>
                </div>
                <div className="flex justify-center mt-4 mx-2">
                    <button className="bg-darkishbrown transition hover:bg-darkbrown rounded-lg text-white text-xl font-bold px-16 md:px-24 py-3" onClick={() => InfoboxF("https://reddit.com/r/battlejellies")}>
                        Battle Jellies 2 Subreddit
                    </button>
                </div>
            </div>
            <div className="bg-lightgrey pt-4 pb-5">
                <p className="text-4xl text-white text-center font-Staat mt-4">
                    Back to Games:
                </p>
                <div className="flex justify-center">
                    <button className="mt-4 bg-lightishgrey transition hover:bg-grey text-white text-center text-lg font-bold rounded-lg px-14 py-2" onClick={() => InfoboxFSelf("/games")}>
                        Games
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ThisGame;