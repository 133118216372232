import React from "react";
import './App.css';
import './index.css';
import {useParams} from "react-router-dom";

import image from "./pianorun_logo_blank.png";
import banner from "./banner_spikelaunch.png";
import slsc1 from "./slsc1.png";
import slsc2 from "./slsc2.png";
import slsc3 from "./slsc3.png";
import slsc4 from "./slsc4.png";
import slsc5 from "./slsc5.png";
import slsc6 from "./slsc6.png";
import googleplay from "./googleplay.png";
import appstore from "./appstore.png";

import changelogList from "./changelogs.json";

function InfoboxF(link) {
    window.open(link);
}

function InfoboxFSelf(link) {
    window.open(link, "_self");
}

function ThisGame() {
    const {update} = useParams();
    const changelogs = changelogList.spikelaunch;
    console.log(update);
    const changelog = changelogs[update];
    let features;
    let updates;
    let fixes;
    if (changelog.features.length == 0) features = "No new features in this update.";
    else features = changelog.features.map(element => <li>• {element}</li>);
    if (changelog.updates.length == 0) updates = "No new updates in this update.";
    else updates = changelog.updates.map(element => <li>• {element}</li>);
    if (changelog.fixes.length == 0) fixes = "No new features in this update.";
    else fixes = changelog.fixes.map(element => <li>• {element}</li>);
    return (
        <div className="bg-black pt-20">
            <div className="mt-16 ml-16">
                <p className="text-4xl text-white font-bold mt-8">
                    Spike Launch: Version {changelog.version} Changelog
                </p>
                <p className="text-lg">Users credited in the changelog made suggestions for features to be added.</p>
                <div className="mt-5">
                    <p className="text-xl text-white font-bold">Features:</p>
                    <ul className="text-lg text-white">
                        {features}
                    </ul>
                </div>
                <div className="mt-4">
                    <p className="text-xl text-white font-bold">Updates:</p>
                    <ul className="text-lg text-white">
                        {updates}
                    </ul>
                </div>
                <div className="mt-4">
                    <p className="text-xl text-white font-bold">Bug Fixes:</p>
                    <ul className="text-lg text-white">
                        {fixes}
                    </ul>
                </div>
            </div>
            <div className="mt-16 ml-16 pb-5">
                <p className="text-2xl text-white font-Staat mt-5">
                    Download the game:
                </p>
                <div className="md:flex mt-1 -ml-4">
                    <button className="transition ease-in-out hover:scale-[1.1] mr-2" onClick={() => InfoboxF("https://play.google.com/store/apps/details?id=com.brokenpiano.com.spikelaunch")}>
                        <img src={googleplay} alt="Google Play" width={200}/>
                    </button>
                    <button className="transition ease-in-out hover:scale-[1.1]" onClick={() => InfoboxF("https://apps.apple.com/us/app/spike-launch/id6449191718")}>
                        <img src={appstore} alt="App Store" width={175}/>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ThisGame;