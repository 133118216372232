import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {default as Navigation} from "./Navigation";
import {default as Footer} from "./Footer";
import {default as Home} from "./Home";
import {default as Games} from "./Games";
 import {default as SpikeLaunch} from "./SpikeLaunch";
  import {default as Changelog} from "./Changelog";
 import {default as PianoRun} from "./PianoRun";
import {default as Legacy} from "./Legacy";
 import {default as BattleJellies2} from "./BattleJellies2";
import {default as AboutUs} from "./AboutUs";
import {default as Policy} from "./Policy";
import {default as Application} from "./Application";
//import {default as Posts} from "./Posts";
//import {default as Post} from "./Post";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Navigation/>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/games" element={<Games/>}/>
         <Route path="/games/legacy" element={<Legacy/>}/>
         <Route path="/games/spike-launch" element={<SpikeLaunch/>}/>
         <Route path="/games/spike-launch/change-log/:update" element={<Changelog/>}/>
         <Route path="/games/piano-run" element={<PianoRun/>}/>
         <Route path="/games/battle-jellies" element={<BattleJellies2/>}/>
        <Route path="/about-us" element={<AboutUs/>}/>
        <Route path="/application" element={<Application/>}/>
        <Route path="/policy" element={<Policy/>}/>
      </Routes>
      <Footer/>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();