import React from "react";
import './App.css';
import './index.css';

import logo from "./logo.png";

function Go(link) {
    window.open(link);
}

function AboutUs() {
    return (
        <div className="bg-grey pt-60 sm:pt-44 md:pt-28 lg:pt-16">
            <div className="mt-32">
                <p className="text-6xl text-white text-center font-Staat mt-28 md:-mt-10">
                    BROKEN PIANO PRIVACY POLICY
                </p>
                <p className="text-lg text-white text-center mt-4 px-8 md:px-20 lg:px-32 xl:px-52">
                    This Privacy Policy ("Policy") is for Broken Piano Studios ("we", "us", "the company"), and all of the services and products that it offers, most notably the game "Spike Launch" available on mobile devices from the Google Play Store and App Store.
                    <br/>This Privacy Policy includes what data we collect from you, what we use it for, who can access it, and what steps you can take to view or delete this data.
                </p>
                <p className="text-lg text-white text-center px-5 pt-3">
                    Last updated June 3rd, 2023
                </p>
            </div>
            <div className="mt-12">
                <h1 className="font-bold text-2xl text-white text-center px-5">1. Introduction</h1>
                <p className="text-lg text-white px-8 md:px-20 lg:px-32 xl:px-52">When you, the User, open any product provided by Broken Piano Studios made with the Unity Engine, such as Spike Launch, you agree to this Privacy Policy, this document. All of our games made with Unity Engine (games made using Scratch, Turbowarp, or Bloxels are not included) are owned and controlled by Broken Piano Studios.<br/><br/>Please read through the Policy carefully and completely. If you disagree with any part of the Policy, you cannot play our games or use our services.</p>
            </div>
            <div className="mt-12">
                <h1 className="font-bold text-2xl text-white text-center px-5">2. Data We Collect</h1>
                <p className="text-lg text-white px-8 md:px-20 lg:px-32 xl:px-52">Out of our games made with Unity Engine, Spike Launch is the only one currently to collect any form of data.
	<br/>We collect data from all users who sign into Google Play Games. Players on Android devices have the opportunity to sign into Google Play Games. While none of the data is personally identifiable, we will be able to see how many users have signed into Google, which achievements have been obtained and how many of each, and other non-identifiable data.
    <br/>For more information on data collected when signing into Google Play Games, see the <a href="https://policies.google.com/privacy?hl=en-US" className="font-bold text-brown hover:text-brown">Google Privacy Policy</a>.
    <br/><br/>We work with IronSource to display advertisements, if you so choose to view them. Whether or not you , we collect you watch the in-app advertisements, we collect and share your data with IronSource. We collect:
    <br/>1. Your approximate location.
    <br/>2. Your app activity in relation to advertisements watched.
    <br/>3. Your app performance.
    <br/>4. Your Advertising ID, an ID number associated with your device that allows advertisements to play.
    <br/>This data is collected regardless of if you watch advertisements in the game. This data is required. You may contact IronSource to request your data be deleted.
	<br/><br/>We do not collect or store any data not listed here.</p>
            </div>
            <div className="mt-12">
                <h1 className="font-bold text-2xl text-white text-center px-5">3. Using Your Data</h1>
                <p className="text-lg text-white px-8 md:px-20 lg:px-32 xl:px-52">
                1. We use your Google Play Games data to let Google Play Games Services work, viewing your achievements, and adding/editing the status of them. We also may use this to try other Google Play Games Services, should we add them at a later time inside our products, and view Google Play Games data on a larger scale to see patterns and make improvements from an analytical perspective.
                <br/>2. IronSource uses your sensitive data in order to display in-app advertisements, if you choose to watch them, collect analytical data and share it with us, and protect against fraud and maintain security measures for the IronSource SDK.
                <br/>In the event that your data is compromised, we will attempt to delete the data and contact you as soon as possible.
                </p>
            </div>
            <div className="mt-12">
                <h1 className="font-bold text-2xl text-white text-center px-5">4. Access to Your Data</h1>
                <p className="text-lg text-white px-8 md:px-20 lg:px-32 xl:px-52">Those working at Broken Piano Studios have access to your data. These are Staff members specifically chosen by us. We will not use or misuse your data in any ways that this Policy does not state. If we find out that data was used in a way not stated on this Policy by one of our Staff members, we will delete that data and notify you as soon as possible using the email that you have given us.
                <br/><br/>Other third party services may be able to access your data. IronSource will have all the data noted previously. Google Play Games, if you choose to sign in, will hold your data as well. Again, if you have any inqueries about Google's use of your data, please see their Privacy Policy linked previously.
                <br/><br/>At any time you may request, by emailing <a href="mailto:emailus@brokenpiano.org" className="font-bold text-brown hover:text-brown">emailus@brokenpiano.org</a>, to have your Advertising ID deleted. If you re-enter the game, your data will be recollected automatically. This will not delete the data connected to your Google Play Games account - sign out of the service through your Settings app to stop data collection there.
                </p>
            </div>
            <div className="mt-12">
                <h1 className="font-bold text-2xl text-white text-center px-5">5. Changes to the Policy</h1>
                <p className="text-lg text-white px-8 md:px-20 lg:px-32 xl:px-52">Any changes to the Policy will go into effect 14 days after the changes are published. Changes to the Policy will be announced on the official Broken Piano Discord server, and sent to those part of the Broken Piano Mailing List. You can sign up for the mailing list and/or join the Discord server at our <a href="/" className="font-bold text-brown hover:text-brown">home page</a>. It's recommended to check this page regularly for changes to the Policy.</p>
            </div>
            <div className="mt-12">
                <h1 className="font-bold text-2xl text-white text-center px-5">6. Contact</h1>
                <p className="text-lg text-white px-8 md:px-20 lg:px-32 xl:px-52">Any questions about the Policy should be emailed at <a href="mailto:emailus@brokenpiano.org" className="font-bold text-brown hover:text-brown">emailus@brokenpiano.org</a>, or asked in a direct message to the Staff members in the official Broken Piano Studios Discord server.</p>
            </div>
            <div className="mt-12">
                <h1 className="font-bold text-2xl text-white text-center px-5">7. Relevant Links</h1>
                <p className="text-lg text-white px-8 md:px-20 lg:px-32 xl:px-52">Our website: <a href="/" className="font-bold text-brown hover:text-brown">https://brokenpiano.org/</a>
	<br/>Privacy Policy: <a href="/policy" className="font-bold text-brown hover:text-brown">https://brokenpiano.org/policy/</a>
    <br/>Public email address: <a href="mailto:emailus@brokenpiano.org" className="font-bold text-brown hover:text-brown">emailus@brokenpiano.org</a>
	<br/>Official Discord server: <a href="https://discord.gg/C2GHU7H6bp" className="font-bold text-brown hover:text-brown">discord.gg/C2GHU7H6bp</a>
</p>
            </div>
        </div>
    );
}

export default AboutUs;