import React from "react";
import './App.css';
import {NavLink} from "react-router-dom";

import facebook from "./facebook2.png";
import youtube from "./youtube2.png";
import twitter from "./twitter2.png";
import reddit from "./reddit2.png";
import discord from "./discord2.png";

function Link(link) {
    window.open(link);
}

function Footer() {
    return (
        <div>
            <footer className="py-5 bg-grey text-xl">
                <p className="text-center text-white ml-2 font-bold">
                    Broken Piano
                </p>
                <div className="flex justify-center text-white mr-3">
                    <NavLink className="navbar-brand transition hover:text-verylightbrown" to="/games">
                        <p className="text-base">
                            Games
                        </p>
                    </NavLink>
                    <NavLink className="navbar-brand -mr-5 transition hover:text-verylightbrown" to="/about-us">
                        <p className="text-base">
                            About Us
                        </p>
                    </NavLink>
                </div>
                <div className="flex justify-center text-white ml-3 -mt-2">
                    <NavLink className="navbar-brand transition hover:text-verylightbrown" to="/policy">
                        <p className="text-base">
                            Privacy Policy
                        </p>
                    </NavLink>
                </div>
                <div className="">
                    <div className="mt-2 flex justify-center space-x-4">
                        <button className="transition ease-in-out hover:scale-[1.2]" onClick={() => Link("https://www.facebook.com/Broken-Piano-103217712371089")}>
                            <img src={facebook} alt="Facebook" className=""/>
                        </button>
                        <button className="transition ease-in-out hover:scale-[1.2]" onClick={() => Link("https://twitter.com/brokenpiano1")}>
                            <img src={twitter} alt="Twitter" className=""/>
                        </button>
                        <button className="transition ease-in-out hover:scale-[1.2]" onClick={() => Link("https://www.youtube.com/channel/UCdeEq_rgorYMnt9V3dYPDkQ")}>
                            <img src={youtube} alt="Youtube" className=""/>
                        </button>
                        <button className="transition ease-in-out hover:scale-[1.2]" onClick={() => Link("https://www.reddit.com/r/battlejellies/")}>
                            <img src={reddit} alt="Reddit" className=""/>
                        </button>
                        <button className="transition ease-in-out hover:scale-[1.2]" onClick={() => Link("https://discord.gg/C2GHU7H6bp")}>
                            <img src={discord} alt="Discord" className=""/>
                        </button>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;