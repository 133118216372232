import React from "react";
import './App.css';
import './index.css';

import {Helmet} from "react-helmet";

import image from "./pianorun_logo_blank.png";
import banner from "./banner_spikelaunch.png";
import slsc1 from "./slsc1.png";
import slsc2 from "./slsc2.png";
import slsc3 from "./slsc3.png";
import slsc4 from "./slsc4.png";
import slsc5 from "./slsc5.png";
import slsc6 from "./slsc6.png";
import slsc7 from "./slsc7.png";
import slsc8 from "./slsc8.png";
import googleplay from "./googleplay.png";
import appstore from "./appstore.png";

import logo from "./sl_logo.png";
import background from "./sl_background.png";

function InfoboxF(link) {
    window.open(link);
}

function InfoboxFSelf(link) {
    window.open(link, "_self");
}

function ThisGame() {
    return (
        <div className="bg-black pt-20">
            <Helmet>
                <title>Spike Launch</title>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#1420c7" />
                <meta
                name="description"
                content="Spike Launch is a fast-paced arcade game - launch your spike, hit crates, and get a new highscore!"
                />
            </Helmet>
            <div className="">
                <div className="" style={{ backgroundImage: `url(${background})`, width: "100%", height: "100%"}}>
                    <div className="">
                        <div className="flex justify-center">
                            <img src={logo} width={325} className="pt-24 animate-slideup"/>
                        </div>
                        <p className="text-3xl text-white text-center font-bold mt-16 animate-slidepause">How long can you last?</p>
                        <div className="flex justify-center mx-2 mt-2 pb-36 animate-slidepause">
                            <button className="transition ease-in-out hover:scale-[1.1] mr-1" onClick={() => InfoboxF("https://play.google.com/store/apps/details?id=com.brokenpiano.com.spikelaunch")}>
                                <img src={googleplay} alt="Google Play" width={250}/>
                            </button>
                            <button className="transition ease-in-out hover:scale-[1.1]" onClick={() => InfoboxF("https://apps.apple.com/us/app/spike-launch/id6449191718")}>
                                <img src={appstore} alt="App Store" width={215}/>
                            </button>
                        </div>
                    </div>
                </div>
                
                <div className="">
                    <p className="text-2xl text-white text-center font-bold mt-8 px-5">
                        Aim for the highest score!
                    </p>
                    <p className="text-lg text-white text-center xs:mx-3 md:mx-5 lg:mx-12 mt-10">
                        Launch yourself out of a cannon, break crates, avoid danger, and use a variety of power-ups!
                        <br/>As you continue flying, obstacles will fall faster and the game will get harder. How long can you survive?
                        <br/><br/>You can unlock new skins, maps, and achievements as you improve.
                    </p>
                </div>
                <div className="mt-32">
                    <div className="flex justify-center">
                        <iframe className="border-4 border-slblue rounded-xl mx-2" width="800" height="450" src="https://youtube.com/embed/_NChrsayOl8" title="Spike Launch Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="mt-40">
                    <p className="text-5xl text-white text-center font-Staat mt-20">
                        SCREENSHOTS:
                    </p>
                    <div className="justify-items-center flex justify-center mt-5 xs:mx-1 md:mx-32 lg:mx-72">
                        <div class="xs:mx-2 md:mx-5 py-2 lg:pt-12">
                        <div class="-m-1 flex flex-wrap md:-m-2">
                            <div class="flex w-1/4 flex-wrap">
                            <div class="w-full p-1 md:p-2">
                                <img
                                alt="gallery"
                                class="block h-full w-full rounded-lg object-cover object-center"
                                src={slsc1} />
                            </div>
                            </div>
                            <div class="flex w-1/4 flex-wrap">
                            <div class="w-full p-1 md:p-2">
                                <img
                                alt="gallery"
                                class="block h-full w-full rounded-lg object-cover object-center"
                                src={slsc2} />
                            </div>
                            </div>
                            <div class="flex w-1/4 flex-wrap">
                            <div class="w-full p-1 md:p-2">
                                <img
                                alt="gallery"
                                class="block h-full w-full rounded-lg object-cover object-center"
                                src={slsc3} />
                            </div>
                            </div>
                            <div class="flex w-1/4 flex-wrap">
                            <div class="w-full p-1 md:p-2">
                                <img
                                alt="gallery"
                                class="block h-full w-full rounded-lg object-cover object-center"
                                src={slsc4} />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="justify-items-center flex justify-center mt-1 xs:mx-1 md:mx-32 lg:mx-72">
                        <div class="xs:mx-2 md:mx-5 py-2 lg:pt-12">
                        <div class="-m-1 flex flex-wrap md:-m-2">
                            <div class="flex w-1/4 flex-wrap">
                            <div class="w-full p-1 md:p-2">
                                <img
                                alt="gallery"
                                class="block h-full w-full rounded-lg object-cover object-center"
                                src={slsc5} />
                            </div>
                            </div>
                            <div class="flex w-1/4 flex-wrap">
                            <div class="w-full p-1 md:p-2">
                                <img
                                alt="gallery"
                                class="block h-full w-full rounded-lg object-cover object-center"
                                src={slsc6} />
                            </div>
                            </div>
                            <div class="flex w-1/4 flex-wrap">
                            <div class="w-full p-1 md:p-2">
                                <img
                                alt="gallery"
                                class="block h-full w-full rounded-lg object-cover object-center"
                                src={slsc7} />
                            </div>
                            </div>
                            <div class="flex w-1/4 flex-wrap">
                            <div class="w-full p-1 md:p-2">
                                <img
                                alt="gallery"
                                class="block h-full w-full rounded-lg object-cover object-center"
                                src={slsc8} />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="text-5xl text-white text-center font-Staat mt-40">
                        Progress & Postcards
                    </p>
                    <div className="mt-2">
                        <p className="text-lg text-white text-center mt-5 mx-3">New characters, postcards, and graphics arrive in Update 1.1 of Spike Launch!</p>
                        <div className="flex justify-center mt-5">
                            <iframe className="border-4 border-slblue rounded-xl" width="600" height="350" src="https://youtube.com/embed/Ve0uwsaIq2E" title="Spike Launch Trailer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="text-5xl text-white text-center font-Staat mt-40">
                        Download Today For Free!
                    </p>
                    <div className="md:flex justify-center mt-2">
                        <div className="flex justify-center">
                        <button className="transition ease-in-out hover:scale-[1.1] md:mr-3" onClick={() => InfoboxF("https://play.google.com/store/apps/details?id=com.brokenpiano.com.spikelaunch")}>
                            <img src={googleplay} alt="Google Play" width={250} height={200}/>
                        </button>
                        </div>
                        <div className="flex justify-center">
                        <button className="transition ease-in-out hover:scale-[1.1]" onClick={() => InfoboxF("https://apps.apple.com/us/app/spike-launch/id6449191718")}>
                            <img src={appstore} alt="App Store" width={215}/>
                        </button>
                        </div>
                    </div>
                </div>
                <div className="bg-lightgrey pt-4 pb-5 mt-32">
                    <p className="text-4xl text-white text-center font-Staat mt-4">
                        Back to Games:
                    </p>
                    <div className="flex justify-center">
                        <button className="mt-4 bg-lightishgrey transition hover:bg-grey text-white text-center text-lg font-bold rounded-lg px-14 py-2" onClick={() => InfoboxFSelf("/games")}>
                            Games
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThisGame;